import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import Section from '../components/Common/Section'

const Privacidad = props => (
  <Layout>
    <SEO
      title="AS News | Aviso de privacidad"
      description="AS Consulting Group utiliza cookies, web beacons y/u otras tecnologías en nuestra página de internet, por medio de las cuales podemos monitorear su comportamiento, horario y tiempo de navegación, consultas realizadas y páginas de internet de origen, con la finalidad de ofrecerle un mejor servicio y experiencia al navegar."
      url={props.location.href}
    />
    <div
      style={{
        backgroundImage: `url(${require('../images/background_asnews_us.png')})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Section>
        <div
          style={{
            minHeight: '100vh',
            display: 'flex',
            alignItems: 'center',
            maxWidth: '700px',
            margin: '0 auto',
            backgroundColor: 'white',
            padding: '2rem 1rem',
            boxShadow: '80px 0 90px 0 #ffffff, -80px 0 90px 0 #ffffff',
          }}
        >
          <div style={{ textAlign: 'justify', fontSize: '1.2rem' }}>
            <h2>Aviso de privacidad</h2>
            <p>
              En términos de lo establecido por la Ley General de Protección de
              Datos Personales en Posesión de Sujetos Obligados, publicada en el
              Diario Oficial de la Federación el 26 de enero de 2017, (la
              “Ley”), por este medio Arreguin Sánchez y Asociados S.C. (en lo
              sucesivo “AS Consulting Group), establece el presente Aviso de
              Privacidad de conformidad con lo siguiente:
            </p>
            <p>
              Arreguin Sánchez y Asociados S.C. con domicilio Obrero Mundial 644
              Col. Atenor Salas, Benito Juárez, C.P. 03020, Ciudad de México, es
              la entidad responsable del tratamiento de sus datos personales.
            </p>
            <div>
              <h4>1. Encargado</h4>
              <p>
                AS Consulting Group ha designado como encargado de su política de
                privacidad y de este Aviso de Privacidad al Encargado de
                Privacidad, quien puede ser localizado en el correo electrónico{' '}
                <a href="mailto:mrojas@asconsultores.com.mx">
                  mrojas@asconsultores.com.mx
                </a>
                , con domicilio en Petén 27 int 301, Col. Piedad Narvarte,
                Ciudad de México. Por medio del Encargado de Privacidad usted
                podrá ejercer sus derechos ARCO (acceso, rectificación,
                cancelación u oposición) y la revocación de su consentimiento
                para el tratamiento de datos personales. El Encargado de
                Privacidad tendrá a obligación de dar contestación a sus
                solicitudes en un plazo máximo de 20 (veinte) días contados a
                partir de la recepción de la solicitud para ejercer los derechos
                ARCO.
              </p>
            </div>
            <div>
              <h4>2. Datos Personales que se Recaban.</h4>
              <p>
                AS Consulting Group recaba los siguientes datos personales: (i)
                nombre y/o denominación/razón social; (ii) lugar y fecha de
                nacimiento; (iii) sexo; (iv) ocupación; (v) estado civil; (vi)
                domicilio; (vii) correo electrónico; (viii) teléfono móvil y
                fijo; y (ix) clave del Registro Federal de Contribuyentes (x)
                datos de la institución bancaria en la cual recibirían
                depósitos; (xi) estados de cuenta bancarios; (xii) gastos e
                ingresos del ejercicio; (xiii) información confidencial; (xiv)
                e.firma y sus contraseña; (xv) datos de clientes y proveedores y
                sus contraseñas; La información mencionada en los incisos
                anteriores será recabada en nuestra página de internet, la cual
                es <a href="https://www.asxpert.com">www.asxpert.com</a> y{' '}
                <a href="https://www.asxpert.com.mx">www.asxpert.com.mx</a> y
                mediante nuestro centro de atención telefónica al{' '}
                <a href="tel:+525558404611">55 5840 4611</a> con la finalidad de
                que un funcionario de se comunique con el interesado para
                concertar una cita y/o enviarle información que pudiera ser de
                su interés Se recabará información financiera necesaria para
                liquidar el servicio, tal como número de cuenta bancaria e
                institución correspondiente, y datos de tarjeta de crédito. En
                caso de que usted no esté en disposición de proporcionar los
                datos personales que requiere AS Consulting Group podrá, sin
                responsabilidad alguna, no proporcionar de inicio el servicio
                correspondiente al cliente, al no contar con los elementos
                necesarios para cumplir adecuadamente con sus obligaciones
                frente a usted y de conformidad con la legislación aplicable.
              </p>
            </div>
            <div>
              <h4>3. Finalidad del Tratamiento de Datos Personales.</h4>
              <p>
                Los datos personales en posesión de AS Consulting Group serán
                utilizados para las siguientes finalidades: (i) proporcionar la
                atención al o a los servicios requeridos por el Cliente; (ii)
                para ser almacenados en nuestra base de datos cuando sea
                necesario para el cumplimiento de las obligaciones de AS
                Consulting Group a usted y de conformidad con la Ley y la legislación
                aplicable; (iii) para cumplir con las obligaciones regulatorias;
                (iv) para fines publicitarios de los servicios y novedades de AS
                Consultores; (v) Informar sobre cambios en los servicios;
                (vi)Evaluar la calidad del servicio; (vii) Prospección
                Comercial; y (viii) Para determinar el monto de los servicios y
                productos que sean ofrecidos en venta por AS Consulting Group o que
                los proveedores le ofrezcan a AS Consulting Group y otros fines
                compatibles o análogos con el objeto social de AS Consulting Group y
                que no constituyan un acto ilegal, quedando convenido que usted
                acepta la transferencia de datos que pudiera realizarse, en su
                caso a las entidades que formen parte directa o indirectamente
                de AS Consulting Group. Así mismo usted acepta y autoriza dicha
                transferencia, para el caso en que se realice a terceros,
                nacionales o extranjeros (entidades o personas distintas de AS
                Consulting Group, reconociendo que, bajo ningún supuesto o
                circunstancia serán compartidos sus datos sensibles,
                patrimoniales o financieros, que no deriven de la relación
                jurídica o que no sean necesarios o no den origen a la misma. En
                adición a lo anterior, en caso de que se requieran tratar por
                una finalidad que no se encuentre vinculada con la relación
                jurídica antes señalada, la misma será comunicada mediante la
                modificación al presente el aviso de privacidad, el cual será
                puesto a su disposición nuevamente en el tiempo y forma que
                correspondan.
              </p>
            </div>
            <div>
              <h4>4. Video y grabaciones.</h4>
              <p>
                En las instalaciones de AS Consilting Group se han instalado
                videocámaras únicamente con fines de seguridad y calidad, por lo
                que las mismas se encuentran en los accesos, recepción y áreas
                comunes.
              </p>
            </div>
            <div>
              <h4>5. Revocación del Consentimiento.</h4>
              <p>
                Los titulares de datos personales podrán en cualquier momento
                solicitar la revocación de su consentimiento, mediante una
                solicitud al Encargado de Privacidad de AS Consilting Group, quien
                puede ser contactado al correo electrónico{' '}
                <a href="mailto:mrojas@asconsultores.com.mx">
                  mrojas@asconsultores.com.mx
                </a>
                , mencionando claramente su nombre y elementos necesarios para
                que AS Consilting Group pueda identificar los datos personales
                respecto de los cuales requiere la revocación.
              </p>
            </div>
            <div>
              <h4>6. Transferencia.</h4>
              <p>
                Los datos personales podrán ser transferidos en estricto
                cumplimiento de la Ley y en caso de ser necesario, a la matriz
                de AS Consulting Group, burós de crédito e instituciones bancarias
                (si así lo autoriza expresamente y sin que esto implique la
                transferencia de datos personales sensibles) y autoridades
                gubernamentales competentes.
              </p>
            </div>
            <div>
              <h4>7. Medidas de Seguridad.</h4>
              <p>
                AS Consulting Group está comprometido con la seguridad de los datos
                personales de sus clientes y el estricto cumplimiento a la Ley,
                por lo que ha establecido todos los procedimientos necesarios
                para su debido resguardo y protección, los cuales son revisados
                periódicamente.
              </p>
            </div>
            <div>
              <h4>8. Modificaciones y Vulneraciones.</h4>
              <p>
                AS Consulting Group se reserva el derecho de modificar el presente
                Aviso de Privacidad, lo cual se informará en la página de
                internet www.ascg.mx informará inmediatamente por
                correo electrónico cualquier vulneración o violación de la
                seguridad que suceda durante o después del tratamiento de sus
                datos personales, siempre que dicha vulneración lo afecte de
                cualquier manera. AS Consulting Group se reserva el derecho de
                efectuar en cualquier momento, modificaciones en la presente
                política de privacidad y adaptarla a novedades legislativas,
                jurisprudenciales, así como prácticas del mercado. Queda bajo
                responsabilidad del usuario leer periódicamente las políticas de
                privacidad para estar al tanto de posibles modificaciones. Una
                vez introducida en el sitio web, la modificación entrará
                automáticamente en vigencia. Con el presente aviso de
                privacidad, los titulares de la información quedan debidamente
                informados de los datos que se recabaron de ellos y con qué
                fines, aceptando los términos contenidos que fueron elaborados
                en cumplimiento a la ley federal de protección de datos
                personales en posesión de los particulares.
              </p>
            </div>
            <div>
              <h4>9. Cookies.</h4>
              <p>
                AS Consulting Group utiliza cookies, web beacons y/u otras
                tecnologías en nuestra página de internet, por medio de las
                cuales podemos monitorear su comportamiento, horario y tiempo de
                navegación, consultas realizadas y páginas de internet de
                origen, con la finalidad de ofrecerle un mejor servicio y
                experiencia al navegar. Al navegar en nuestra página tendrá la
                oportunidad de deshabilitar dichas tecnologías siguiendo las
                instrucciones que arroja la propia página de internet.
              </p>
              <p>Fecha de actualización: marzo de 2018.</p>
            </div>
          </div>
        </div>
      </Section>
    </div>
  </Layout>
)

export default Privacidad
